// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

// Initialize Firebase
let app;
if (process.env.NEXT_PUBLIC_ENV === "development") {
  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_API_KEY_COMIC_DEV,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN_COMIC_DEV,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID_COMIC_DEV,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET_COMIC_DEV,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDING_ID_COMIC_DEV,
    appId: process.env.NEXT_PUBLIC_APP_ID_COMIC_DEV,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID_COMIC_DEV,
  };

  app = initializeApp(firebaseConfig, "shortbread-comics-dev");
  console.log("Using development Firebase config");
} else {
  const firebaseConfig = {
    apiKey: `${process.env.NEXT_PUBLIC_API_KEY_COMIC}`,
    authDomain: `${process.env.NEXT_PUBLIC_AUTH_DOMAIN_COMIC}`,
    projectId: `${process.env.NEXT_PUBLIC_PROJECT_ID_COMIC}`,
    storageBucket: `${process.env.NEXT_PUBLIC_STORAGE_BUCKET_COMIC}`,
    messagingSenderId: `${process.env.NEXT_PUBLIC_MESSAGING_SENDING_ID_COMIC}`,
    appId: `${process.env.NEXT_PUBLIC_APP_ID_COMIC}`,
    measurementId: `${process.env.NEXT_PUBLIC_MEASUREMENT_ID_COMIC}`,
  };
  app = initializeApp(firebaseConfig, "shortbread-comics");
  console.log("Using production Firebase config");
}

const mobileDatabase = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

export default mobileDatabase;

export const AUTHORS_COLLECTION = "authors";
export const MOBILE_LIBRARY_COLLECTION = "comics_library";
export const MOBILE_USERS_COLLECTION = "users";
export const MOBILE_EPISODES_COLLECTION = "comics_episodes";
export const MOBILE_COMING_SOON_LIBRARY = "coming_soon_library";
export const APP_NOTIFICATION_INSTANCES_COLLECTION =
  "app_notification_instances";
export const APP_NOTIFICATION_CAMPAIGNS_COLLECTION = "app_notification_campaigns";

export const COMIC_COLLECTIONS = "comic_collections";

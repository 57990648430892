"use client";

import LoadingSpinner from "@/components/ui/custom/LoadingSpinner";
import { User } from "firebase/auth";
import { useRouter } from "next/navigation";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getIsAuthorized,
  userCanSeeScriptButton,
  userCanSeeSeriesButton,
} from "../lib/authorization";
import { authClient } from "../utils/firebase/firebaseClientConfig";
import {
  checkIsShortbreadArtist,
  fetchIsAdminUser,
  fetchIsUserCreatorOfComic,
  setUserUidField,
} from "../utils/firebase/userOperations";
import { refreshSessionCookie } from "../app/actions/authActions";

interface AuthContextType {
  user: User | null;
  isAdmin: boolean;
  isShortbreadArtist: boolean;
  isAuthorized: boolean;
  canSeeSeriesButton: boolean;
  canSeeScriptButton: boolean;
  isCreatorOfAnyComics: boolean;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  isAdmin: false,
  isShortbreadArtist: false,
  isAuthorized: false,
  canSeeSeriesButton: false,
  canSeeScriptButton: false,
  isCreatorOfAnyComics: false,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isShortbreadArtist, setIsShortbreadArtist] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [canSeeSeriesButton, setCanSeeSeriesButton] = useState(false);
  const [isCreatorOfAnyComics, setIsCreatorOfAnyComics] = useState(false);
  const [canSeeScriptButton, setCanSeeScriptButton] = useState(false);

  useEffect(() => {
    return authClient.onIdTokenChanged(async (user) => {
      if (user) {
        await refreshSessionCookie(await user.getIdToken());
        setUser(user);
        setUserUidField(user.uid);
        Promise.allSettled([
          fetchIsAdminUser(user.uid).then(setIsAdmin),
          checkIsShortbreadArtist(user.uid).then(setIsShortbreadArtist),
          getIsAuthorized(user.uid).then(setIsAuthorized),
          userCanSeeSeriesButton(user.uid).then(setCanSeeSeriesButton),
          userCanSeeScriptButton(user.uid).then(setCanSeeScriptButton),
          fetchIsUserCreatorOfComic(user.uid).then(setIsCreatorOfAnyComics),
        ]).then(() => {
          setLoading(false);
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        isAdmin,
        isShortbreadArtist,
        isAuthorized,
        canSeeSeriesButton,
        isCreatorOfAnyComics,
        canSeeScriptButton,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);

export function withAuth<P extends object>(
  WrappedComponent: React.ComponentType<P & { user: User }>
) {
  return function WithAuth(props: Omit<P, "user">) {
    const { user, loading } = useAuth();
    const router = useRouter();

    useEffect(() => {
      if (!loading && !user) {
        router.push("/login");
      }
    }, [user, loading, router]);

    if (loading || !user) {
      return <LoadingSpinner />;
    }

    return <WrappedComponent {...(props as P)} user={user} />;
  };
}

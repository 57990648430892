// AWS elastic IP addresses for EC2 instances
const LORA_SERVER = "http://3.21.141.45";
const DEV_SERVER = "http://13.59.238.200";
const JOBS_SERVER = "http://18.189.160.51";
const GEN_SERVER01 = "http://3.19.226.109";
const GEN_SERVER02 = "http://3.23.160.130";
const GEN_SERVER03 = "http://3.13.32.123";
const GEN_SERVER04 = "http://18.221.216.202";
const SCREENSHOT_SERVER = "http://3.130.94.119";

const WEBUI_SERVERS = [GEN_SERVER01, GEN_SERVER02];
const COMFY_SERVERS = [DEV_SERVER];

const getRandomGenServer = () => {
  return WEBUI_SERVERS[Math.floor(Math.random() * WEBUI_SERVERS.length)];
};

const getRandomComfyServer = () => {
  return COMFY_SERVERS[Math.floor(Math.random() * COMFY_SERVERS.length)];
};

export const ENDPOINTS = {
  loraDataGeneration: `${LORA_SERVER}:3000`,
  loraTrain: `${LORA_SERVER}:3002`,
  test: `${GEN_SERVER04}:3000`,
  script: `${GEN_SERVER02}:3000`, // there can only be a single one, otherwise local state gets lost
  comfy: `${getRandomComfyServer()}:3000`,
  get default() {
    return `${getRandomGenServer()}:3000`;
  },
  get REMBG() {
    return `${getRandomGenServer()}:3000`;
  },
  screenshot: `${SCREENSHOT_SERVER}:3000`,
};

export const NEXTJS_ROUTES = {
  generate: "/api/generate",
  generateInstant: "/api/generateInstant",
  generateDataset: "/api/characters/generateDataset",
  regenerateSample: "/api/characters/regenerateSample",
  trainLora: "/api/characters/trainLora",
  proofread: "/api/proofread",
  rembg: "/api/rembg",
  translate: "/api/translate",
  screenshotComic: "/api/screenshotComic",
  scriptToWizard: "/api/scriptToWizard",
  flux: "/api/flux",
  scriptProcess: "/api/scripts/process",
  scriptDownloadDocx: "/api/scripts/downloadDocx",
};

import { SeriesPublishStep } from "@/src/components/seriesManagement/series/SeriesForm";
import { NEXTJS_ROUTES } from "@/src/constants/endpoints";
import { MissingFieldsError } from "@/src/constants/errors";
import type {
  EditorEpisode,
  EditorSeries,
  MobileEpisode,
  MobileSeries,
} from "@/src/constants/types/seriesTypes";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import slugify from "slugify";
import { toast } from "sonner";
import { createAuthors, getAuthorsByIds } from "./authorOperations";
import editorDatabase, {
  EPISODES_COLLECTION,
  SERIES_COLLECTION,
  SERIES_TAGS_COLLECTION,
  storage,
  STORAGE_BUCKET_BASE_URL,
  USERS_PUBLISH_COLLECTION,
} from "./firebaseClientConfig";
import mobileDatabase, {
  MOBILE_EPISODES_COLLECTION,
  MOBILE_LIBRARY_COLLECTION,
} from "./mobileFirebase";

export const syncMobileSeriesToEditor = async (
  editorSeries: EditorSeries,
  publishStep: SeriesPublishStep
) => {
  const editorSeriesDoc = doc(
    collection(editorDatabase, SERIES_COLLECTION),
    editorSeries.id
  );
  const mobileSeriesDoc = doc(
    collection(mobileDatabase, MOBILE_LIBRARY_COLLECTION),
    editorSeries.id
  );

  const editorSeriesSnapshot = await getDoc(editorSeriesDoc);
  if (!editorSeriesSnapshot.exists()) {
    throw new Error("Series ID does not exist!");
  }

  const mobileSeriesData: MobileSeries = {
    authors: editorSeries.creatorIds,
    banner_url: editorSeries.bannerURL,
    full_screen_cover_url: editorSeries.fullScreenCoverURL,
    tagline: editorSeries.tagline,
    categories: editorSeries.tags,
    cover_img: editorSeries.coverURL,
    updated_at: Timestamp.now(),
    description: editorSeries.description,
    spiciness: editorSeries.spiciness,
    episode_price: editorSeries.episodePrice,
    free_episodes_count: editorSeries.freeEpisodesCount,
    release_date: editorSeries.releaseDate,
    id: editorSeries.id,
    name: editorSeries.name,
    ongoing: editorSeries.ongoing,
    publish_state: "published",
    release_day: editorSeries.releaseDay,
    custom_meta_tag: editorSeries.customMetaTag,
  };

  try {
    switch (publishStep) {
      case SeriesPublishStep.UNPUBLISHED: {
        await setDoc(
          mobileSeriesDoc,
          {
            ...mobileSeriesData,
            publish_state: "unpublished",
          },
          { merge: true }
        );
        break;
      }
      case SeriesPublishStep.COMING_SOON: {
        checkMissingFieldsComingSoon({
          name: editorSeries.name,
          coverURL: editorSeries.coverURL,
        });
        await setDoc(
          mobileSeriesDoc,
          {
            ...mobileSeriesData,
            publish_state: "coming_soon",
          },
          { merge: true }
        );
        break;
      }
      case SeriesPublishStep.PUBLISHED: {
        const existingAuthors = await getAuthorsByIds(editorSeries.creatorIds);
        const missingAuthorsIds = editorSeries.creatorIds.filter(
          (id: string) => !existingAuthors.some((author) => author.id === id)
        );
        await createAuthors(missingAuthorsIds);

        checkMissingFieldsPublish({
          bannerURL: editorSeries.bannerURL,
          coverURL: editorSeries.coverURL,
          description: editorSeries.description,
          fullScreenCoverURL: editorSeries.fullScreenCoverURL,
          name: editorSeries.name,
          spiciness: editorSeries.spiciness ?? 0,
          tagline: editorSeries.tagline ?? "",
        });

        await setDoc(
          mobileSeriesDoc,
          {
            ...mobileSeriesData,
            publish_state: "published",
          },
          { merge: true }
        );
        break;
      }
    }
    return true;
  } catch (error) {
    toast.error(`Error syncing mobile series to editor: ${error}`);
    return false;
  }
};

export const syncMobileEpisodetoEditor = async (
  episode: EditorEpisode,
  pages?: string[],
  customEpisode = false,
) => {
  const { id, name, order, seriesId, coverURL, spiciness, episodePrice } =
    episode;

  if (customEpisode) {
    const episodeDoc = doc(
      collection(mobileDatabase, MOBILE_EPISODES_COLLECTION),
      id
    );
    const data: any = {
      id,
      comic_id: seriesId,
      name,
      cover: coverURL,
      created_at: Timestamp.fromDate(new Date(0)),
      order,
      spiciness,
      is_published: true,
    };

    if (pages?.length) {
      data.pages = pages;
    }

    return await setDoc(
      episodeDoc,
      data,
      { merge: true }
    );
  }

  const mobileEpisodeDoc = doc(
    collection(mobileDatabase, MOBILE_EPISODES_COLLECTION),
    id
  );

  const mobileSeriesDoc = doc(
    collection(mobileDatabase, MOBILE_LIBRARY_COLLECTION),
    seriesId
  );

  const mobileSeriesSnapshot = await getDoc(mobileSeriesDoc);
  const latestEpisodeAddedAt = mobileSeriesSnapshot.get('latest_episode_added_at');

  if (episode.createdAt && (!latestEpisodeAddedAt || episode.createdAt.toMillis() > latestEpisodeAddedAt.toMillis())) {
    await updateDoc(mobileSeriesDoc, {
      latest_episode_added_at: episode.createdAt,
    });
  }

  return await setDoc(
    mobileEpisodeDoc,
    {
      comic_id: seriesId,
      cover: coverURL,
      created_at: episode.createdAt,
      id,
      name,
      order,
      pages,
      spiciness,
      episode_price: episodePrice,
      is_published: true,
    },
    { merge: true }
  );
};

export const unpublishEpisode = async (episodeId: string) => {
  const comicsEpisodeDoc = doc(
    collection(mobileDatabase, MOBILE_EPISODES_COLLECTION),
    episodeId
  );

  return updateDoc(comicsEpisodeDoc, {
    is_published: false,
  });
};

export const checkMobileEpisodePages = async (
  episodeId: string
): Promise<boolean> => {
  const comicsEpisodeDoc = doc(
    collection(mobileDatabase, MOBILE_EPISODES_COLLECTION),
    episodeId
  );

  const comicsEpisodeSnapshot = await getDoc(comicsEpisodeDoc);

  if (!comicsEpisodeSnapshot.exists()) {
    return false;
  }

  return (
    comicsEpisodeSnapshot.get("pages") &&
    comicsEpisodeSnapshot.get("pages")?.length > 0
  );
};

export const createSeries = async ({
  id,
  name,
  userId,
}: {
  id: string;
  name: string;
  userId: string;
}) => {
  return runTransaction(editorDatabase, async (transaction) => {
    const usersPublishSnapshot = await transaction.get(
      doc(collection(editorDatabase, USERS_PUBLISH_COLLECTION), userId)
    );

    if (!usersPublishSnapshot.exists()) {
      throw new Error("User is not allowed to publish");
    }

    const seriesDoc = doc(collection(editorDatabase, SERIES_COLLECTION), id);

    const seriesSnapshot = await transaction.get(seriesDoc);

    if (seriesSnapshot.exists()) {
      throw new Error("Series ID already exists");
    }

    return transaction.set(seriesDoc, {
      bannerURL: "",
      collaboratorIds: [],
      coverURL: "",
      createdAt: serverTimestamp(),
      creatorIds: [userId],
      description: "",
      freeEpisodesCount: 0,
      fullScreenCoverURL: "",
      id,
      name,
      publishStep: SeriesPublishStep.UNPUBLISHED,
      tagline: "",
      tags: [],
      updatedAt: serverTimestamp(),
      userId,
    });
  });
};

const checkMissingFieldsPublish = ({
  bannerURL,
  coverURL,
  description,
  fullScreenCoverURL,
  name,
  spiciness,
  tagline,
}: {
  bannerURL: string;
  coverURL: string;
  description: string;
  fullScreenCoverURL: string;
  name: string;
  spiciness: number;
  tagline: string;
}) => {
  const missingFields = [];
  if (!bannerURL) missingFields.push("banner");
  if (!coverURL) missingFields.push("cover");
  if (!description) missingFields.push("description");
  if (!fullScreenCoverURL) missingFields.push("full screen cover");
  if (!name) missingFields.push("name");
  if (!tagline) missingFields.push("tagline");
  if (spiciness === undefined || spiciness == null)
    missingFields.push("spiciness");
  if (missingFields.length) {
    throw new MissingFieldsError(missingFields);
  }
};

const checkMissingFieldsComingSoon = ({
  name,
  coverURL,
}: {
  name: string;
  coverURL: string;
}) => {
  const missingFields = [];
  if (!name) missingFields.push("name");
  if (!coverURL) missingFields.push("cover");
  if (missingFields.length) {
    throw new MissingFieldsError(missingFields);
  }
};

export const updateSeriesFields = async (
  seriesId: string,
  data: Partial<EditorSeries>
) => {
  const seriesDoc = doc(
    collection(editorDatabase, SERIES_COLLECTION),
    seriesId
  );
  return setDoc(
    seriesDoc,
    { ...data, updatedAt: serverTimestamp() },
    { merge: true }
  );
};

export const deleteSeries = async ({ id }: { id: string }) => {
  const editorSeriesDoc = doc(
    collection(editorDatabase, SERIES_COLLECTION),
    id
  );
  const mobileSeriesDoc = doc(
    collection(mobileDatabase, MOBILE_LIBRARY_COLLECTION),
    id
  );
  const seriesSnapshot = await getDoc(editorSeriesDoc);
  if (!seriesSnapshot.exists()) {
    throw new Error("Series ID does not exist!");
  }
  deleteDoc(editorSeriesDoc);
  deleteDoc(mobileSeriesDoc);
};

export const addEpisode = async ({
  episodeId,
  seriesId,
  userId,
}: {
  episodeId: string;
  seriesId: string;
  userId: string;
}) => {
  return runTransaction(editorDatabase, async (transaction) => {
    const seriesDoc = doc(
      collection(editorDatabase, SERIES_COLLECTION),
      seriesId
    );

    const seriesSnapshot = await transaction.get(seriesDoc);

    if (!seriesSnapshot.exists()) {
      throw new Error("Series ID does not exist!");
    }

    const episodeDoc = doc(
      collection(editorDatabase, EPISODES_COLLECTION),
      episodeId
    );

    const episodeSnapshot = await transaction.get(episodeDoc);

    if (episodeSnapshot.exists()) {
      throw new Error("Episode ID already exists!");
    }

    const existengEpisodesQuery = query(
      collection(editorDatabase, EPISODES_COLLECTION),
      where("seriesId", "==", seriesId)
    );

    const existengEpisodesSnapshot = await getDocs(existengEpisodesQuery);

    const existingEpisodesCount = existengEpisodesSnapshot.size ?? 0;

    const episodeData: EditorEpisode = {
      comicsSaveIds: [],
      id: episodeId,
      name: `Episode ${existingEpisodesCount + 1}`,
      order: existingEpisodesCount,
      isPublished: false,
      seriesId,
      updatedAt: Timestamp.now(),
      userId,
      coverURL: "",
      pages: [],
      spiciness: 0,
      episodePrice: 0,
      createdAt: Timestamp.now(),
    };

    return transaction.set(episodeDoc, episodeData);
  });
};

export const uploadScreenshotWithRetry = async (
  comicsSaveId: string,
  partNumber: number,
  retriesLeft = 1
): Promise<string[]> => {
  const screenshotResult = await fetch(NEXTJS_ROUTES.screenshotComic, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      comicsSaveId,
      partNumber,
    }),
  });

  if (screenshotResult.status === 500) {
    throw new Error("Failed to upload screenshot. Internal server error");
  }

  const { result, status } = await screenshotResult.json();

  if (status !== 200) {
    throw new Error("Failed to upload screenshot.");
  }
  const { count, screenshotUrls } = result;

  const existingScreenshotUrls = screenshotUrls.filter((url: string) =>
    Boolean(url)
  );

  if (existingScreenshotUrls.length !== count) {
    throw new Error("Missing screenshots");
  }

  return existingScreenshotUrls;
};

export const regenerateMobileEpisodeImages = async (
  comicsSaveIds: string[]
) => {
  let pages = [];
  for (let idx = 0; idx < comicsSaveIds.length; idx++) {
    const result = await uploadScreenshotWithRetry(comicsSaveIds[idx], idx);
    pages.push(...result);
  }
  pages = pages.sort();
  return pages;
};

export const updateCover = async ({
  cover,
  episodeId,
}: {
  cover: File;
  episodeId: string;
}) => {
  const coverName = slugify(cover.name, { strict: true });
  const storageRef = ref(
    storage,
    `episode_thumbnails/${new Date().getTime()}-${coverName}`
  );
  const uploadResult = await uploadBytes(storageRef, cover);
  const coverURL = STORAGE_BUCKET_BASE_URL + uploadResult.metadata.fullPath;
  updateEditorEpisodeWithDataObject({ episodeId, data: { coverURL } });
  return coverURL;
};

export const updateEditorEpisodeWithDataObject = async ({
  episodeId,
  data,
}: {
  episodeId: string;
  data: Partial<EditorEpisode>;
}) => {
  const episodeDoc = doc(
    collection(editorDatabase, EPISODES_COLLECTION),
    episodeId
  );
  return setDoc(
    episodeDoc,
    { ...data, updatedAt: serverTimestamp() },
    { merge: true }
  );
};

export const updateMobileEpisodeWithDataObject = async ({
  episodeId,
  data,
}: {
  episodeId: string;
  data: Partial<MobileEpisode>;
}) => {
  const episodeDoc = doc(
    collection(mobileDatabase, MOBILE_EPISODES_COLLECTION),
    episodeId
  );
  return setDoc(episodeDoc, data, { merge: true });
};

export const fetchEpisodeByComicId = async (
  comicId: string
): Promise<EditorEpisode[]> => {
  const episodesQuery = query(
    collection(editorDatabase, EPISODES_COLLECTION),
    where("comicsSaveIds", "array-contains", comicId)
  );
  const episodesSnapshot = await getDocs(episodesQuery);
  return episodesSnapshot.docs.map((doc) => doc.data()) as EditorEpisode[];
};

export const addComicToEpisode = async ({
  comicId,
  episodeId,
}: {
  comicId: string;
  episodeId: string;
}) => {
  return runTransaction(editorDatabase, async (transaction) => {
    const episodeDoc = doc(
      collection(editorDatabase, EPISODES_COLLECTION),
      episodeId
    );
    const episodeSnapshot = await transaction.get(episodeDoc);

    if (!episodeSnapshot.exists())
      throw new Error("Episode ID does not exist!");
    const episodeData = episodeSnapshot.data() as EditorEpisode;

    if (episodeData.isPublished)
      throw new Error("Cannot add to an already published episode");
    const comicsSaveIds = episodeData.comicsSaveIds ?? [];

    if (comicsSaveIds.includes(comicId)) return;
    return transaction.update(episodeDoc, {
      comicsSaveIds: [...comicsSaveIds, comicId],
    });
  });
};

export const removeComicFromEpisode = async ({
  comicId,
  episodeId,
}: {
  comicId: string;
  episodeId: string;
}) => {
  return runTransaction(editorDatabase, async (transaction) => {
    const episodeDoc = doc(
      collection(editorDatabase, EPISODES_COLLECTION),
      episodeId
    );
    const episodeSnapshot = await transaction.get(episodeDoc);

    if (!episodeSnapshot.exists())
      throw new Error("Episode ID does not exist!");
    const episodeData = episodeSnapshot.data() as EditorEpisode;

    if (episodeData.isPublished)
      throw new Error("Cannot remove from an already published episode");
    const comicsSaveIds = episodeData.comicsSaveIds ?? [];
    const newComicsSaveIds = comicsSaveIds.filter(
      (id: string) => id !== comicId
    );
    return transaction.update(episodeDoc, { comicsSaveIds: newComicsSaveIds });
  });
};

export const deleteEpisode = async (episodeId: string) => {
  return runTransaction(editorDatabase, async (transaction) => {
    const episodeDoc = doc(
      collection(editorDatabase, EPISODES_COLLECTION),
      episodeId
    );
    const episodeSnapshot = await transaction.get(episodeDoc);

    if (!episodeSnapshot.exists())
      throw new Error("Episode ID does not exist!");
    const episodeData = episodeSnapshot.data() as EditorEpisode;

    if (episodeData.isPublished)
      throw new Error("Cannot delete an already published episode");
    const nextEpisodeQuery = query(
      collection(editorDatabase, EPISODES_COLLECTION),
      where("seriesId", "==", episodeData.seriesId),
      where("order", "==", episodeData.order + 1),
      limit(1)
    );
    const nextEpisodeSnapshot = await getDocs(nextEpisodeQuery);

    if (nextEpisodeSnapshot.size)
      throw new Error("This is not the last episode in the series!");
    return transaction.delete(episodeDoc);
  });
};

export const fetchComicSeries = async (comicsSaveId: string) => {
  const episodesQuery = query(
    collection(editorDatabase, EPISODES_COLLECTION),
    where("comicsSaveIds", "array-contains", comicsSaveId)
  );
  const episodesSnapshot = await getDocs(episodesQuery);
  if (!episodesSnapshot.size) return [];
  const episodesData = episodesSnapshot.docs.map((doc) => doc.data());
  const seriesIds = episodesData.map((episode) => episode.seriesId);
  const seriesQuery = query(
    collection(editorDatabase, SERIES_COLLECTION),
    where("id", "in", seriesIds)
  );
  const seriesSnapshot = await getDocs(seriesQuery);
  return seriesSnapshot.docs.map((doc) => doc.data());
};

export const fetchComicSeriesAndEpisodes = async (comicsSaveId: string) => {
  const episodesQuery = query(
    collection(editorDatabase, EPISODES_COLLECTION),
    where("comicsSaveIds", "array-contains", comicsSaveId)
  );
  const episodesSnapshot = await getDocs(episodesQuery);
  if (!episodesSnapshot.size) return { series: [], episodes: [] };
  const episodesData = episodesSnapshot.docs.map(
    (doc) => doc.data() as EditorEpisode
  );
  const seriesIds = episodesData.map((episode) => episode.seriesId);
  const seriesQuery = query(
    collection(editorDatabase, SERIES_COLLECTION),
    where("id", "in", seriesIds)
  );
  const seriesSnapshot = await getDocs(seriesQuery);
  const seriesData = seriesSnapshot.docs.map(
    (doc) => doc.data() as EditorSeries
  );
  return {
    series: seriesData,
    episodes: episodesData,
  };
};

export const fetchTags = async () => {
  const seriesTagsRef = collection(editorDatabase, SERIES_TAGS_COLLECTION);
  const seriesTagsSnapshot = await getDocs(seriesTagsRef);

  return seriesTagsSnapshot.docs.map((doc) => doc.data());
};

export const fetchIsComicPublished = async (comicsSaveId: string) => {
  const episodesQuery = query(
    collection(editorDatabase, EPISODES_COLLECTION),
    where("comicsSaveIds", "array-contains", comicsSaveId)
  );

  const episodesSnapshot = await getDocs(episodesQuery);

  if (!episodesSnapshot.size) {
    return false;
  }

  const episodesData = episodesSnapshot.docs.map((doc) => {
    return doc.data();
  });

  return episodesData.some((episode) => episode.isPublished);
};
